.show-info-box{
    display: flex;
    justify-content: center;
    max-width: 450px;
    min-height: 140px;
    margin: auto;
    /*margin-bottom: -20px;*/
    align-items: center;   /* <---- NEW    */
    background-color: #F9FEFF;
    line-height: 10px;
    border: 1px solid rgb(88, 72, 7);
}

.ticket-btn{
    padding: 10px 20px;
    border: 1px solid #664d03;
    color: #842029;
    background-color: #ffc10794;
}