#Press {
  position: relative;
  transform: rotate(3deg);
  width: 40vw;
  min-width: 37px;
  max-width: 234px;
  margin: 14px;
  cursor: pointer;
}

.accordion {
  color: black;
  background-color: transparent;
}

.accordion-item {
  color: black;
  box-shadow: #842029;
  border-radius: 4px !important;
}
.accordion-body{
  background: #b5ad52;
}

.accordion-header {
  border-radius: 4px !important;
}
.accordion-flush .accordion-item .accordion-button {
  background-color: #e8b315;
  border-radius: 4px;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 4px !important;
}


.accordion-button:focus {
  color: unset;
}

.App {
  min-height: 620px;
  text-align: center;
  background-color: #b5ad52;
}


.App-logo {
  max-width:360px;
  pointer-events: none;
  margin: -10px auto;
}


.App-link {
  color: #61dafb;
}


.social-links {
  margin: 10px 0;
  font-size: 1.4em;
}

.modal-body {
  background-color:#b5ad52;
  border-bottom-left-radius: calc(0.5rem - 1px);
  border-bottom-right-radius: calc(0.5rem - 1px);
}

.modal-header {
  background-color: #a77947;
}

.modal-footer {
  background-color: #a77947;
}

.modal-title {
  color: #492b07;
}
.social-ul {
  display: inline-flex;
  list-style: none;
  margin: 0;
}

.nav-item {
  margin: 0 47px 0 0;

}



  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }