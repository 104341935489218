.social-links {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    font-size: 1.5em;
  }
  
  .social-ul {
    display: inline-flex;
    list-style: none;
    margin: 0;
  }
  
  .nav-item {
    margin: 0 47px 0 0;
  
  }