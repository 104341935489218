body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  background-color: #7f0501;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.carousel > .carousel-indicators{
 bottom:-32px;
}

.carousel-item > img {
        
        max-height:360px;
        pointer-events: none;
}

@media (max-width: 920px) {
  #BanPhotoCar {
    padding-top: 5%;
  }
    .carousel-item > img {
      max-width:80vh;
    }
}
.main {
    background-color: #b5ad52
}

.form{
    background-color: rgb(151, 59, 59, .46);
    border-radius: 5px;
    /* width: 550px; */
    margin: 20px 10px;
    padding: 20px;
    /* height: 100%; */
}

.form-header{
    text-align: center;
    font-weight: bold;
    font-size: 1.3em;
    color: '#00000087';
}
.form-body{
    text-align: center;
}

.form-body > *{
    padding: 5px;
}

.form__label{
    width: 40%;
}

.form_input{
    width: 60%;
}

.footer{
    text-align: center;
}

#message{
    width: 100%;
    height: 100%; 
    box-sizing: border-box;
}

.form-body {
    text-align: left;
}

.form__label {
    font-size: .6em;
    font-weight:bold;
    float:left;
}

/*.pair {
    float: left;
}*/
.marquee {
  position: relative;
  margin: 0 0;
  }

  figure.marquee p {
    z-index: 2;
    position: absolute;    
    text-align: center;
    height: 105%;
    width: 100%;
}
figure.marquee p:before {
  z-index: 2;
   display: inline-block;
   vertical-align: middle;
   height: 100%;
   content: '';
}

.marquee-img{
  max-width: 400px;
  width: -webkit-fill-available;
}


 


#Press {
  position: relative;
  -webkit-transform: rotate(3deg);
          transform: rotate(3deg);
  width: 40vw;
  min-width: 37px;
  max-width: 234px;
  margin: 14px;
  cursor: pointer;
}

.accordion {
  color: black;
  background-color: transparent;
}

.accordion-item {
  color: black;
  box-shadow: #842029;
  border-radius: 4px !important;
}
.accordion-body{
  background: #b5ad52;
}

.accordion-header {
  border-radius: 4px !important;
}
.accordion-flush .accordion-item .accordion-button {
  background-color: #e8b315;
  border-radius: 4px;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 4px !important;
}


.accordion-button:focus {
  color: unset;
}

.App {
  min-height: 620px;
  text-align: center;
  background-color: #b5ad52;
}


.App-logo {
  max-width:360px;
  pointer-events: none;
  margin: -10px auto;
}


.App-link {
  color: #61dafb;
}


.social-links {
  margin: 10px 0;
  font-size: 1.4em;
}

.modal-body {
  background-color:#b5ad52;
  border-bottom-left-radius: calc(0.5rem - 1px);
  border-bottom-right-radius: calc(0.5rem - 1px);
}

.modal-header {
  background-color: #a77947;
}

.modal-footer {
  background-color: #a77947;
}

.modal-title {
  color: #492b07;
}
.social-ul {
  display: inline-flex;
  list-style: none;
  margin: 0;
}

.nav-item {
  margin: 0 47px 0 0;

}



  @-webkit-keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }



  @keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
.show-info-box{
    display: flex;
    justify-content: center;
    max-width: 450px;
    min-height: 140px;
    margin: auto;
    /*margin-bottom: -20px;*/
    align-items: center;   /* <---- NEW    */
    background-color: #F9FEFF;
    line-height: 10px;
    border: 1px solid rgb(88, 72, 7);
}

.ticket-btn{
    padding: 10px 20px;
    border: 1px solid #664d03;
    color: #842029;
    background-color: #ffc10794;
}
.social-links {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    font-size: 1.5em;
  }
  
  .social-ul {
    display: inline-flex;
    list-style: none;
    margin: 0;
  }
  
  .nav-item {
    margin: 0 47px 0 0;
  
  }
.admin-main{
    background-color: #b5ad52;
    height:100%
}
.main {
    background-color: #b5ad52
}

.form{
    background-color: rgb(151, 59, 59, .46);
    border-radius: 5px;
    /* width: 550px; */
    margin: 20px 10px;
    padding: 20px;
    /* height: 100%; */
}

.form-header{
    text-align: center;
    font-weight: bold;
    font-size: 1.3em;
    color: '#00000087';
}
.form-body{
    text-align: center;
}

.form-body > *{
    padding: 5px;
}

.form__label{
    width: 40%;
}

.form_input{
    width: 60%;
}

.footer{
    text-align: center;
}

