


.carousel > .carousel-indicators{
 bottom:-32px;
}

.carousel-item > img {
        
        max-height:360px;
        pointer-events: none;
}

@media (max-width: 920px) {
  #BanPhotoCar {
    padding-top: 5%;
  }
    .carousel-item > img {
      max-width:80vh;
    }
}