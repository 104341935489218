.main {
    background-color: #b5ad52
}

.form{
    background-color: rgb(151, 59, 59, .46);
    border-radius: 5px;
    /* width: 550px; */
    margin: 20px 10px;
    padding: 20px;
    /* height: 100%; */
}

.form-header{
    text-align: center;
    font-weight: bold;
    font-size: 1.3em;
    color: '#00000087';
}
.form-body{
    text-align: center;
}

.form-body > *{
    padding: 5px;
}

.form__label{
    width: 40%;
}

.form_input{
    width: 60%;
}

.footer{
    text-align: center;
}
