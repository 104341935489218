#message{
    width: 100%;
    height: 100%; 
    box-sizing: border-box;
}

.form-body {
    text-align: left;
}

.form__label {
    font-size: .6em;
    font-weight:bold;
    float:left;
}

/*.pair {
    float: left;
}*/