.marquee {
  position: relative;
  margin: 0 0;
  }

  figure.marquee p {
    z-index: 2;
    position: absolute;    
    text-align: center;
    height: 105%;
    width: 100%;
}
figure.marquee p:before {
  z-index: 2;
   display: inline-block;
   vertical-align: middle;
   height: 100%;
   content: '';
}

.marquee-img{
  max-width: 400px;
  width: -webkit-fill-available;
}


 

